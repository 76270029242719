import { Button, Icon, Spin } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import {
  QuestionAnswer,
  QuestionItem,
  Milestone,
  Entry,
} from "../../interfaces";
import Question from "./Question";
import QuestionHeight from "./QuestionHeight";

interface Props {
  questions: QuestionItem[];
  answers: QuestionAnswer[];
  setAnswers: (answers: QuestionAnswer[]) => void;
  setQuestions: any;
  heightQuestions: QuestionItem[];
  nextStep: () => void;
  milestone?: Milestone;
  setMilestoneDate?: React.Dispatch<React.SetStateAction<string>>;
  customAnswer: string;
  setCustomAnswer: React.Dispatch<React.SetStateAction<string>>;
  qIndex: number;
  setQIndex: React.Dispatch<React.SetStateAction<number>>;
  hasQuestions: boolean;
  answer: QuestionAnswer | undefined;
  changeAnswer: (answer: QuestionAnswer) => void;
  addQuestion: (question: QuestionItem) => void;
  setEntry: React.Dispatch<React.SetStateAction<Entry>>;
  milestoneDate?: string;
}

const Questions: React.FC<Props> = ({
  questions,
  answers,
  setAnswers,
  heightQuestions,
  nextStep,
  setQuestions,
  milestone,
  setMilestoneDate,
  setEntry,
  customAnswer,
  setCustomAnswer,
  qIndex,
  setQIndex,
  hasQuestions,
  answer,
  changeAnswer,
  addQuestion,
  milestoneDate,
}) => {
  const [showHeight, setShowHeight] = useState(false);
  const isPregnancyMilestone = milestone?.age_weeks === -700;

  const [initialHeight, initialWeight] = useMemo(() => {
    if (!heightQuestions || !heightQuestions.length) {
      return [0, 0];
    }
    const [height, weight] = [
      answers.find((a) => a.type === "height"),
      answers.find((a) => a.type === "weight"),
    ];
    let [initialHeight, initialWeight] = [0, 0];

    if (!!height) initialHeight = parseInt(height.answer_string[0]);
    if (!!weight) initialWeight = parseInt(weight.answer_string[0]);

    return [initialHeight, initialWeight];
  }, [heightQuestions, answers]);

  if (!hasQuestions) {
    if (!heightQuestions || !heightQuestions.length) {
      return (
        <div className="mx-auto d-table my-3">
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
          />
        </div>
      );
    } else if (!showHeight && !isPregnancyMilestone) {
      setShowHeight(true);
    }
  }

  return (
    <>
      <div className="mt-3"></div>
      {showHeight ? (
        <QuestionHeight
          questions={heightQuestions}
          changeAnswer={changeAnswer}
          initialHeight={initialHeight}
          initialWeight={initialWeight}
          setEntry={setEntry}
        />
      ) : (
        <Question
          question={questions[qIndex]}
          answer={answer}
          changeAnswer={changeAnswer}
          setQuestions={setQuestions}
          milestone={milestone}
          setMilestoneDate={setMilestoneDate}
          qIndex={qIndex}
          customAnswer={customAnswer}
          setCustomAnswer={setCustomAnswer}
          milestoneDate={milestoneDate}
        />
      )}
      <div style={{ height: 15 }}></div>
      <div className="question-actions">
        <div className="d-flex justify-content-center align-items-center">
          <Button
            type="primary"
            shape="circle"
            size="large"
            icon="left"
            onClick={() => {
              showHeight ? setShowHeight(false) : setQIndex(qIndex - 1);
              addQuestion(questions[qIndex]);
            }}
            disabled={qIndex === 0}
          />
          &nbsp;
          <span>
            {showHeight
              ? questions.length + (!!heightQuestions.length ? 1 : 0)
              : qIndex + 1}
            /{questions.length + (!!heightQuestions.length ? 1 : 0)}
          </span>
          &nbsp;
          <Button
            type="primary"
            shape="circle"
            size="large"
            icon={
              (
                !heightQuestions || !heightQuestions.length
                  ? qIndex === questions.length - 1
                  : showHeight
              )
                ? "check"
                : "right"
            }
            onClick={() => {
              if (showHeight || qIndex === questions.length - 1) {
                if (showHeight || !heightQuestions || !heightQuestions.length) {
                  addQuestion(questions[qIndex]);
                  nextStep();
                } else {
                  addQuestion(questions[qIndex]);
                  setShowHeight(true);
                }
              } else {
                addQuestion(questions[qIndex]);
                setQIndex(qIndex + 1);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Questions;
