import { Button, Checkbox, Icon, InputNumber, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CartItem } from "../../interfaces/cart";
import { itemInvalid } from "../../pages/Cart/CheckoutHelper";
import { sagaMiddleware } from "../../store";
import { fetchBookById } from "../../store/sagas/books";
import BookModal from "../BookModal/BookModal";
import DetailArrow from "../Icons/DetailArrow";
import EyeOpen from "../Icons/EyeOpen";
import Thrash from "../Icons/Thrash";
import "./Book.scss";

interface Props {
  item: CartItem;
  onChangeItem: (item: CartItem) => void;
  onRemoveItem: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  itemIndex: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const Book: React.FC<Props> = ({
  item,
  onChangeItem,
  onRemoveItem,
  itemIndex,
  setLoading,
}) => {
  const history = useHistory();
  const [completeBook, setCompleteBook] = useState<CartItem>(item);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [bookPreviewModal, setBookPreviewModal] = useState(false);
  const [totalExtraPagesCost, setTotalExtraPagesCost] = useState(0);
  const [totalWithExtraPages, setTotalWithExtraPages] = useState(0);

  useEffect(() => {
    setLoading(true);
    sagaMiddleware.run<any>(
      fetchBookById,
      parseInt(item.book_id),
      (error: any | null, bookBD?: any) => {
        if (error !== null || !bookBD) {
          setLoading(false);
          history.push("/");
          return;
        }
        try {
          item.babypages = bookBD.babypages;
          item.title = bookBD.title;
          setCompleteBook(item);
        } catch (error) {
          Modal.error({
            title: "Error",
            content: error.message ? error.message : "Error loading book",
          });
        }
      },
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    const calculateTotalCost = () => {
      const totalWithoutExtraPages = item.options.reduce((acc, current) => {
        return acc + current.price * current.qty;
      }, 0);

      const totalExtraPages = item.options.reduce((acc, current) => {
        if (
          current.selected &&
          current.extra_pages &&
          current.extra_pages.selected
        ) {
          return acc + current.extra_pages.price * current.extra_pages.qty;
        }
        return acc;
      }, 0);

      const totalWithExtraPages = totalWithoutExtraPages + totalExtraPages;

      return {
        totalWithoutExtraPages,
        totalWithExtraPages,
        totalExtraPages,
      };
    };
    const { totalExtraPages, totalWithExtraPages } = calculateTotalCost();
    setTotalExtraPagesCost(totalExtraPages.toFixed(2));
    setTotalWithExtraPages(totalWithExtraPages.toFixed(2));
  }, [item]);

  const cartDividerStyle = itemIndex > 0 ? "cart-divider" : "";

  const removeSmallOrLarge = (title: string) => {
    let titleResult;
    titleResult = title.includes("Large")
      ? title.replace("Large", "")
      : title.replace("Small", "");
    return titleResult;
  };

  const replaceSmallOrLarge = (title: string) => {
    let titleResult;
    titleResult = title.includes("Large")
      ? title.replace("Large", '8"x8"')
      : title.replace("Small", '6"x6"');

    return titleResult;
  };

  return (
    <div className={`row cart-item ${cartDividerStyle}`}>
      <div className="col-md-5 col-4">
        <img src={item.thumbnail} alt="Thumbnail" className="w-100" />
      </div>
      <div className="col-md-7 col-8 book-product">
        <div className="row justify-content-start align-items-baseline">
          <div className="col-12">
            <h3 className="m-0 cart-item-title  ">
              <strong>{item.title}</strong>
            </h3>
          </div>
        </div>
        <>
          {item.options
            .filter((option: any) => option.active)
            .map((option, index) => (
              <div
                className="row justify-content-start align-items-center my-1 book-product"
                key={index}
              >
                <div className="col-3 book-qty">
                  <InputNumber
                    className="w-100"
                    value={option.selected ? option.qty : 0}
                    min={0}
                    max={100}
                    step={1}
                    onChange={(value) => {
                      const options = item.options.map((o, i) => {
                        if (o.extra_pages) {
                          if (i === index) {
                            return {
                              ...option,
                              qty: value || 0,
                              selected: !!value && value > 0 ? true : false,
                              extra_pages: {
                                ...option.extra_pages,
                              },
                            };
                          }
                        } else {
                          if (i === index) {
                            return {
                              ...option,
                              qty: value || 0,
                              selected: !!value && value > 0 ? true : false,
                            };
                          }
                        }

                        return o;
                      });

                      onChangeItem({
                        ...item,
                        options,
                        total: options
                          .filter((o) => !!o.selected)
                          .reduce((total, curr) => {
                            if (curr.total_with_pages) {
                              return (
                                total +
                                parseFloat(curr.total_with_pages) *
                                  parseInt(curr.qty)
                              );
                            } else {
                              return (
                                total +
                                parseFloat(curr.price) * parseInt(curr.qty)
                              );
                            }
                          }, 0),
                        totalWithoutPages: options
                          .filter((o) => !!o.selected)
                          .reduce((total, curr) => {
                            return (
                              total +
                              parseFloat(curr.price) * parseInt(curr.qty)
                            );
                          }, 0),
                      });
                    }}
                  />
                </div>

                <div className="col-9 book-option-padding">
                  <div className="d-flex flex-column"></div>
                  <p className="book-option-title">
                    {removeSmallOrLarge(option.title)}
                  </p>
                  <p className="book-option-price">
                    ${option.price.toFixed(2)}
                  </p>
                </div>
              </div>
            ))}
        </>
        {totalExtraPagesCost > 0 ? (
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between my-2">
              <p className="book-option-title">Additional Pages:</p>
              <p className="book-option-price">${totalExtraPagesCost}</p>
            </div>

            <div className="d-flex justify-content-start align-items-center">
              <details>
                <summary className="cart-book-actions clickable">
                  Details
                </summary>
                {item.options.map((option) => {
                  if (option.selected && option.extra_pages) {
                    // return <p className="book-option-title">{option.extra_pages.qty} - {replaceSmallOrLarge(option.extra_pages.title)} ${option.extra_pages.price.toFixed(2)}</p>
                    return (
                      <div className="d-flex justify-content-between">
                        <p className="book-option-title book-item-details">
                          {option.extra_pages.qty} -{" "}
                          {replaceSmallOrLarge(option.extra_pages.title)}{" "}
                        </p>
                        <p className="book-option-title book-item-details ml-3">
                          ${option.extra_pages.price.toFixed(2)}
                        </p>
                      </div>
                    );
                  }
                })}
              </details>
            </div>
          </div>
        ) : null}
        <div className="d-flex justify-content-between my-2 book-option-price">
          <p>Subtotal:</p>
          <p>${totalWithExtraPages}</p>
          {/* <p>${item.total.toFixed(2)}</p> */}
        </div>

        <div className="row justify-content-start mt-3 mb-2 cart-book-actions-margin">
          <span className="mr-1">
            {" "}
            <EyeOpen />{" "}
          </span>
          <p
            className="cart-book-actions mr-2"
            onClick={() => {
              setBookPreviewModal(true);
            }}
          >
            {" "}
            Preview{" "}
          </p>

          <span className="pl-2 mr-1 book-item-border-left">
            {" "}
            <Thrash />{" "}
          </span>
          <p className="cart-book-actions" onClick={onRemoveItem}>
            Remove
          </p>
        </div>
      </div>
      {!!completeBook.babypages ? (
        <BookModal
          leftPage={completeBook.babypages[currentIndex].thumbnail}
          rightPage={completeBook.babypages[currentIndex].thumbnail_content}
          visible={bookPreviewModal}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          pagesLimit={completeBook.babypages.length - 1}
          onClose={() => setBookPreviewModal(false)}
          useOldBabypages={false}
        />
      ) : null}
    </div>
  );
};

export default Book;